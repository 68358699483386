import React, {Component} from "react";

class FilterButton extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  toggleFilter = () => {
    this.props.toggleFilter(this.props.type, this.props.choice);
  };

  render() {
    return (
      <button className={`filterButton ${this.props.active} fb-${this.props.choice}`} type="submit"
              onClick={() => this.toggleFilter()}>
        {this.props.choice}
      </button>
    )
  }
}

export default FilterButton;