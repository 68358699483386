import React, {Component} from "react";
import FilterButton from './FilterButton';

class FilterSelector extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
  }

  toggleFilter = (filterType, filterEntry) => {
    console.log(`Toggling ${filterType} filter ${filterEntry}`);
    this.props.toggle(filterType, filterEntry);
  };

  render() {
    return (
      <div className="filterGroup" id={`filterGroup-${this.props.name}`}>
        {this.props.choices.map((choice, index) => {
        const active = this.props.filter.includes(choice) ? "Active" : "Inactive";
        return (
          <FilterButton
            key={index} choice={choice} active={active}
            toggleFilter={this.toggleFilter} type={this.props.name}
          />
        )
      })}
      </div>
    )
  }
}

export default FilterSelector;