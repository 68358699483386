import React, { Component, Fragment } from 'react';


class Frame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frame: null,
    };
  }

  render_field = (field) => {
    const decoded = typeof (field[1]) === 'object' ? JSON.stringify(field[1]) : field[1];

    return (
      <Fragment>
        <div>{field[0]}: </div>
        <div>{decoded}</div>
      </Fragment>
    )
  };

  render_payload = (f, payload) => {
    return (
      f.payload.map((line, index) => <Fragment key={index}>{this.render_field(line)}</Fragment>)
    )
  };

  contents = () => {
    const f = this.props.frame;
    if (!f.frame_type) {
      return (
        <div className="payload">
          <div>N/A</div>
          <div>Couldn't decode frame</div>
          <div> </div>
        </div>
      )
    }

    return (
      <Fragment>
        <div className="payload">
          {this.render_payload(f, f.payload)}
        </div>
      </Fragment>
    )
  };

  render() {
    // console.log(this.props.frame);
    const f = this.props.frame;
    const plug_id = f.plugid ? "0x" + f.plugid : "";
    const frame_type = f.frame_type ? f.frame_type : "N/A";

    return (
      <div className={`frame type-${frame_type}`}>
        <div className="frame-top frame-id">{this.props.index}</div>
        <div className="frame-top frame-timestamp">{f.timestamp}</div>
        <div className="frame-top frame-plugid">{plug_id}</div>
        <div className="frame-top frame-type">{frame_type}</div>
        <div className="frame-top frame-header">{f.raw_data}</div>
        <div className="frame-contents">
          {this.contents()}
        </div>
      </div>
    )
  }
}

export default Frame;
