import React, { Component } from 'react';
import Frame from "./Frame";
import Paginator from "./Paginator";

class FrameContainer extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.state = {
      filteredFrames: null,
      framesPerPage: 500,
      currentPage: 1,
      pages: 1,
    }
  }

  changePage = (page) => {
    this.setState({
      currentPage: page,
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.frames === this.props.frames) {
      return;
    }
    console.log("FrameContainer will update. frames:");
    console.log(this.props.frames);

    if (!this.props.frames) {
      this.setState({filteredFrames: null})
      return;
    }

    let frameTypes = [];
    let plugIDs = [];
    if (this.props.filters != null) {
      frameTypes = this.props.filters['frameTypes'];
      plugIDs = this.props.filters['plugIDs'];
    }
    this.filterFrames(this.props.frames.lines, frameTypes, plugIDs)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!nextProps.frames) {
      return;
    }

    let frameTypes = [];
    let plugIDs = [];
    if (nextProps.filters != null) {
      frameTypes = nextProps.filters['frameTypes'];
      plugIDs = nextProps.filters['plugIDs'];
    }
    //this.setState({filteredFrames: nextProps.frames.lines})
    this.filterFrames(nextProps.frames.lines, frameTypes, plugIDs);
  }

  filterFrames = (lines, frameTypes, plugIDs) => {
    if (!lines) {
      return;
    }

    console.log("Filtering frames. Before:");
    console.log({lines});
    const filteredFrames = lines
      .filter((frame => {
        if (frameTypes.length === 0) {
          return true;
        }
        return frameTypes.includes(frame.frame_type);
      }))
      .filter((frame => {
        if (plugIDs.length === 0) {
          return true;
        }
        return plugIDs.includes(frame.plugid);
      }));

    console.log({filteredFrames});
    this.setState({
      filteredFrames,
      num_frames: filteredFrames.length,
      pages: Math.ceil(filteredFrames.length / this.state.framesPerPage),
    })
  };

  render() {
    // {this.state.frames.map(frame => <Frame frame={frame} />)}
    console.log("Rendering frame container");
    console.log({StateFilteredFrames: this.state.filteredFrames});
    console.log({PropsFrames: this.props.frames});
    if (!this.state.filteredFrames) {
      return <div className="loading">
        <p>Loading</p>
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div></div>
    }
    // note : Chrome can't handle css grid above 1000 elements!
    const begin = this.state.framesPerPage * (this.state.currentPage - 1);
    const end = this.state.framesPerPage * this.state.currentPage;
    const frame_slice = this.state.filteredFrames.slice(begin, end);
    return (
      <div className="paginatedContainer">
        <div className="pagination-top">
          <Paginator onChange={this.changePage} pages={this.state.pages} page={this.state.currentPage}/>
        </div>
        <div className="frameContainer">
          {frame_slice.map(frame => <Frame frame={frame} key={frame.index} index={frame.index}/>)}
        </div>
        <div className="pagination-bot">
          <Paginator onChange={this.changePage} pages={this.state.pages} page={this.state.currentPage}/>
        </div>
      </div>
    );
  }
}

export default FrameContainer;