import React, { Component } from "react";


class PaginatorButton extends Component {
  render() {
    const active = this.props.page !== undefined ? (this.props.page === this.props.current ? " active" : "") : "";
    const myClassName = "paginator-button" + active;
    if (this.props.page) {
      return (
        <div className={myClassName} onClick={() => this.props.onClick(this.props.page)}>
          {this.props.page}
        </div>
      )
    } else {
      return (
        <div className={myClassName} onClick={this.props.onClick}>
          {this.props.text}
        </div>
      )
    }

  }
}


class Paginator extends Component {
  constructor(props) {
    super(props);
  }

  hasPrev = () => {
    return this.props.page > 0;
  };

  hasNext = () => {
    return this.props.page < this.props.pages;
  };

  prev = () => {
    if(this.hasPrev()) {
      this.setPage(this.props.page - 1);
    }
  };

  next = () => {
    console.log(`Next page. Current : ${this.props.page}. Pages: ${this.props.pages}`);
    if(this.hasNext()) {
      this.setPage(this.props.page + 1);
    }
  };
  
  setPage = (page) => {
    this.props.onChange(page);
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.pages !== this.props.pages) {
      if (this.props.page > nextProps.pages) {
        this.setPage(nextProps.pages)
      }
    }
  }

  render() {
    const pageList = [];
    for (let p = 1; p <= this.props.pages; p++) {
      pageList.push(
        <PaginatorButton page={p} current={this.props.page} onClick={this.setPage} key={p}/>
      )
    }
    return (
      <div className="paginator">
        <PaginatorButton text="<" onClick={this.prev}/>
        {pageList}
        <PaginatorButton text=">" onClick={this.next}/>
      </div>
    )
  }
}

export default Paginator