import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import FrameContainer from './components/FrameContainer';
import FilterSelector from './components/FilterSelector';
import './App.css';


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route path='/' component={Main} />
      </BrowserRouter>
    )
  }
}

class Main extends Component {
  urlRef = React.createRef();

  // target URL with 2000 lines
  // targetUrl = 'http://manager.myesmart.net/v1/uploads/download/00-00-02-75-00-01/171018114237_network_in.log';
  // target with 36 lines
  defaultUrl = 'http://manager.myesmart.net/v1/uploads/download/00-00-36-00-00-07/171115112106_network_in.log';

  constructor(props) {
    super(props);

    let params = new URLSearchParams(props.location.search);
    let targetUrl = params.get('file') || this.defaultUrl;
    console.log({targetUrl});

    this.state = {
      logUrl: targetUrl,
      frames: null,
      displayedFrames: null,
      activeFilters: {
        frameTypes: [],
        plugIDs: [],
      },
    };

    this.toggleFilter = this.toggleFilter.bind(this);
  };


  toggleFilter = (type, value) => {
    if (this.state.activeFilters[type].includes(value)) {
      this.setState((state) => {
        const list = state.activeFilters[type].filter((item) => (item !== value));
        console.log({list});
        const activeFilters = {...state.activeFilters};
        activeFilters[type] = list;
        return {activeFilters}
      })
    } else {
      this.setState((state) => {
        const list = state.activeFilters[type].concat(value);
        console.log({list});
        const activeFilters = {...state.activeFilters};
        activeFilters[type] = list;
        return {activeFilters}
      })
    }
  };

  fetchNetworkLogs(url) {
    //const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const proxyUrl = 'https://13kgjl33te.execute-api.eu-central-1.amazonaws.com/dev/v1/file/';
    this.setState({
      frames: null,
      activeFilters: {
        frameTypes: [],
        plugIDs: [],
      },
    });
    console.log("Fetching " + url);

    fetch(proxyUrl + url)
      .then(response=>response.json())
      .then(frames=>this.setState({
        frames,
        pages: frames.length / this.state.framesPerPage,
      }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.logUrl !== this.state.logUrl) {
      console.log("Updating frame container with new URL");
      this.fetchNetworkLogs(this.state.logUrl)
    }
  }

  componentDidMount() {
    this.fetchNetworkLogs(this.state.logUrl)
  }

  loadNewFile = e => {
    e.preventDefault();
    console.log("Loading new network_in log: " + this.urlRef.current.value);
    this.setState({
      logUrl: this.urlRef.current.value,
    });

    const searchParams = new URLSearchParams();
    searchParams.set('file', this.urlRef.current.value);
    const logUrl = searchParams.toString();
    this.props.history.push(`?${logUrl}`);
    console.log("added file to search params too");
    console.log(this.props.history);
  };

  render() {
    const frame_types = this.state.frames ? this.state.frames.filters.frame_types : [];
    const plug_ids = this.state.frames ? this.state.frames.filters.plug_ids : [];
    const proc_time = this.state.frames ? this.state.frames.tools.processing_time.toFixed(3) + 's' : "N/A";
    const n_frames = this.state.frames ? this.state.frames.len : '0';
    const sersmart_ver = this.state.frames ? this.state.frames.tools.sersmart : 'N/A';
    return (
      <div className="App">
        <header className="App-header urlBar">
          <form onSubmit={this.loadNewFile}>
            <label>Load a new log file: </label>
            <input type="text" ref={this.urlRef} name="url" placeholder="http://manager.myesmart.net/v1/uploads/download/network_in.log"/>
            <button type="submit">Load</button>
          </form>
        </header>

        <div className="filters">
          <div className="filterHeader">Selectable Filters</div>
          <div className="filterName">Frame Types</div>
          <FilterSelector name="frameTypes" choices={frame_types}
                          filter={this.state.activeFilters['frameTypes']}
                          toggle={this.toggleFilter}
          />
          <div className="filterName">Plug IDs</div>
          <FilterSelector name="plugIDs" choices={plug_ids}
                          filter={this.state.activeFilters['plugIDs']}
                          toggle={this.toggleFilter}
          />
          <div className="filterName">Some Stats</div>
          <ul>
            <li>Processing time: {proc_time}</li>
            <li>Frames: {n_frames}</li>
            <li>Sersmart: v{sersmart_ver}</li>
          </ul>
        </div>
        <FrameContainer className="paginatedFrameContainer" frames={this.state.frames} filters={this.state.activeFilters}/>
        <div className="footer">
          <p>Andrew Watson - 2019 - eSMART Technologies SA</p>
        </div>
      </div>
    );
  }
}

export default App;
